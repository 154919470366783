import { ComponentType } from '@angular/cdk/portal';
import { Injectable, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';

import { AbstractDialog } from 'src/app/abstracts/abstract-dialog';

@Injectable({ providedIn: 'root' })
export class DialogService {
  constructor(public dialog: MatDialog) {}

  open<DialogData, DialogResult>(
    component: TemplateRef<AbstractDialog<DialogData, DialogResult>>,
    config?: MatDialogConfig<DialogData>
  ): MatDialogRef<AbstractDialog<DialogData, DialogResult>, DialogResult>;
  open<DialogData, DialogResult>(
    component: ComponentType<AbstractDialog<DialogData, DialogResult>>,
    config?: MatDialogConfig<DialogData>
  ): MatDialogRef<AbstractDialog<DialogData, DialogResult>, DialogResult>;
  open<DialogData, DialogResult>(
    component:
      | ComponentType<AbstractDialog<DialogData, DialogResult>>
      | TemplateRef<AbstractDialog<DialogData, DialogResult>>,
    config?: MatDialogConfig<DialogData>
  ): MatDialogRef<AbstractDialog<DialogData, DialogResult>, DialogResult> {
    return this.dialog.open(component, config);
  }
}
