{
  "name": "wemap-cfe",
  "private": true,
  "version": "2.4.1",
  "description": "Wemap customer frontend.",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "start:production": "ng serve --configuration production",
    "pre-commit": "lint-staged",
    "build": "ng build --configuration development",
    "build:production": "ng build --configuration production",
    "build:preprod": "ng build --configuration preprod",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "test:ci": "ng test --no-watch --no-progress --code-coverage --browsers=ChromeHeadless",
    "test:e2e:chrome": "CHROMEONLY=true npx playwright test",
    "lint": "ng lint",
    "lint:fix": "ng lint --fix",
    "prettier": "npx prettier --write ."
  },
  "dependencies": {
    "@angular/animations": "^18.2.13",
    "@angular/cdk": "^18.2.14",
    "@angular/common": "^18.2.13",
    "@angular/compiler": "^18.2.13",
    "@angular/core": "^18.2.13",
    "@angular/forms": "^18.2.13",
    "@angular/material": "^18.2.14",
    "@angular/platform-browser": "^18.2.13",
    "@angular/platform-browser-dynamic": "^18.2.13",
    "@angular/router": "^18.2.13",
    "@faker-js/faker": "^8.3.1",
    "@mapbox/geo-viewport": "^0.5.0",
    "@mapbox/mapbox-gl-draw": "^1.4.3",
    "@mapbox/mapbox-gl-draw-static-mode": "^1.0.1",
    "@mapbox/mapbox-gl-geocoder": "^4.7.2",
    "@mapbox/sphericalmercator": "^1.0.5",
    "@mapbox/togeojson": "^0.16.0",
    "@messageformat/core": "^3.4.0",
    "@ngx-translate/core": "^15.0.0",
    "@tailwindcss/container-queries": "^0.1.1",
    "@turf/bbox": "^7.1.0",
    "@turf/bbox-polygon": "^7.1.0",
    "@turf/boolean-intersects": "^7.1.0",
    "@turf/helpers": "^7.1.0",
    "@turf/turf": "^7.1.0",
    "@types/mapbox-gl": "^3.4.0",
    "@wemap/geo": "^12.8.9",
    "chart.js": "^4.4.0",
    "cheap-ruler": "^4.0.0",
    "countries-list": "^3.0.6",
    "country-flag-icons": "^1.5.9",
    "coveralls": "^3.1.1",
    "dayjs": "^1.11.13",
    "events": "^3.3.0",
    "font-awesome": "^4.7.0",
    "jquery": "^3.7.1",
    "jsoneditor": "^9.10.5",
    "mapbox-gl": "^1.13.0",
    "mapbox-gl-draw-circle": "^1.1.2",
    "ngx-image-cropper": "^7.1.2",
    "ngx-loading-buttons": "^18.0.1",
    "ngx-translate-messageformat-compiler": "^7.0.0",
    "numeral": "^2.0.6",
    "object-path": "^0.11.8",
    "prismjs": "^1.29.0",
    "proj4": "^2.15.0",
    "remeda": "^2.14.0",
    "rxjs": "~7.8.0",
    "sass-loader": "^13.3.2",
    "shpjs": "^4.0.4",
    "tabulator-tables": "^6.2.1",
    "trumbowyg": "^2.27.3",
    "tslib": "^2.3.0",
    "uuid": "^10.0.0",
    "xlsx": "^0.18.5",
    "xng-breadcrumb": "^12.0.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.12",
    "@angular-eslint/builder": "^18.4.3",
    "@angular-eslint/eslint-plugin": "^18.4.3",
    "@angular-eslint/eslint-plugin-template": "^18.4.3",
    "@angular-eslint/schematics": "^18.4.3",
    "@angular-eslint/template-parser": "^18.4.3",
    "@angular/cli": "^18.2.12",
    "@angular/compiler-cli": "^18.2.13",
    "@babel/core": "^7.23.3",
    "@babel/plugin-proposal-optional-chaining": "^7.21.0",
    "@babel/preset-env": "^7.23.3",
    "@playwright/test": "^1.49.1",
    "@types/chart.js": "^2.9.41",
    "@types/jasmine": "~4.3.0",
    "@types/jquery": "^3.5.29",
    "@types/jsoneditor": "^9.9.5",
    "@types/mapbox__mapbox-gl-draw": "^1.4.6",
    "@types/node": "^20.10.0",
    "@types/tabulator-tables": "^6.2.0",
    "@typescript-eslint/eslint-plugin": "^8.0.0",
    "@typescript-eslint/parser": "^8.0.0",
    "autoprefixer": "^10.4.16",
    "babel-loader": "^9.1.3",
    "css-loader": "^6.8.1",
    "eslint": "^9.8.0",
    "eslint-config-prettier": "^9.0.0",
    "eslint-plugin-import": "^2.31.0",
    "eslint-plugin-prettier": "^5.0.1",
    "globals": "15.13",
    "husky": "^8.0.3",
    "jasmine-core": "~4.6.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "lint-staged": "^15.1.0",
    "postcss": "^8.4.32",
    "prettier": "^3.1.1",
    "prettier-plugin-tailwindcss": "^0.5.11",
    "sass": "^1.69.5",
    "tailwindcss": "^3.4.1",
    "typescript": "^5.5.3",
    "typescript-eslint": "^8.22.0"
  },
  "lint-staged": {
    "*.ts": [
      "prettier --write",
      "eslint --fix"
    ]
  },
  "eslintConfig": {
    "env": {
      "jasmine": true
    }
  },
  "browser": {
    "fs": false,
    "path": false,
    "os": false
  }
}
