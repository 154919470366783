import { Injectable, Injector } from '@angular/core';
import { NoopScrollStrategy } from '@angular/cdk/overlay';

import { DialogService } from '../../services/dialog/dialog.service';
import { AbstractModelService, CallbackListener } from 'src/app/abstracts/abstract-model.service';
import { List } from '~models/list/list.model';
import { NotificationType } from 'src/app/notification/notification.service';
import batch from 'src/app/shared/utils/batch';
import { DialogListChoiceComponent } from '~dialogs/list/choice/choice.component';
import { DialogListCreateOptionsComponent } from '~dialogs/list/create-options/create-options.component';
import { firstValueFrom } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ListService extends AbstractModelService<List> {
  objectClass = List;
  objectPath = '/v3.0/lists';

  constructor(
    public injector: Injector,
    private dialog: DialogService
  ) {
    super(injector);

    this.listenerList = {
      handleEmitListsUpdated: [] as CallbackListener[]
    };
  }

  async deleteLists(selectedItems: List[]) {
    const payload = selectedItems.map((item) => ({ id: item.id }));

    return this.http
      .delete(`${this.apiUrl}${this.objectPath}`, {
        body: payload,
        headers: {
          'Content-Type': 'application/json'
        }
      })
      .toPromise();
  }

  async updateLists(selectedItems: List[], updates: any) {
    const update: any = (selectedItems: List[], args: any) => {
      const payload = selectedItems.map((item) => ({ id: item.id, ...args }));

      return this.http
        .put(`${this.apiUrl}${this.objectPath}`, payload, {
          headers: {
            'Content-Type': 'application/json'
          }
        })
        .toPromise();
    };

    return batch(update, selectedItems, updates)
      .then((response: any) => {
        this.emit('handleEmitListsUpdated', {
          response: response,
          selectedItems: selectedItems,
          updates: updates
        });
        return response;
      })
      .catch((error) => {
        this.notificationService.launchNotification(
          NotificationType.Danger,
          this.translateService.instant('list.notification.ERROR-UPDATING-S')
        );
        return Promise.reject(error);
      });
  }

  queryPaginated(params: Record<string, any>) {
    const searchFields = ['query', 'sort_by_name', 'search', 'tags'];
    const useSearchUrl = searchFields.find(
      (field) => Object.prototype.hasOwnProperty.call(params, field) && params[field] !== null
    );

    // search params is here to force ?search endpoint
    delete params.search;

    const req = this.http.get(`${this.apiUrl}${this.objectPath}${useSearchUrl ? '/search' : ''}`, { params });

    return req
      .toPromise()
      .then(this.responseToModel)
      .catch((error: any) => {
        this.notificationService.launchNotification(
          NotificationType.Danger,
          this.translateService.instant('list.notification.ERROR-GETTING-S')
        );
        return Promise.reject(error);
      });
  }

  chooseLists() {
    return firstValueFrom(
      this.dialog
        .open(DialogListChoiceComponent, {
          scrollStrategy: new NoopScrollStrategy()
        })
        .afterClosed()
    );
  }

  openCreateOptions() {
    this.dialog.open(DialogListCreateOptionsComponent, {
      scrollStrategy: new NoopScrollStrategy()
    });
  }
}
